import React, { useEffect } from 'react';
import '../App.css';

function Intro() {
    useEffect(() => {
        const loadAds = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error('Adsbygoogle failed to load:', e);
            }
        };
        // Load ads when the component mounts
        loadAds();
    }, []);

  return (
    <main>
      <section id="intro">
        <h1>Introduction</h1>
        
        <p>
        Vedic Astrology, also known as Jyotish, is an ancient system of astrological divination originating in India. With roots stretching back thousands of years, this intricate practice offers profound insights into human nature, life circumstances, and spiritual growth. Unlike its Western counterpart, Vedic Astrology employs the sidereal zodiac, aligning its calculations with the fixed stars rather than the seasons.
        </p>
        <p>
        At its core, Vedic Astrology seeks to illuminate the karmic patterns and potential of an individual's life. It views the positions of celestial bodies at the time of one's birth as a cosmic snapshot, revealing the fruits of past actions and the seeds of future experiences. This system doesn't aim to predict a fixed fate, but rather to provide a map of possibilities and challenges, empowering individuals to navigate their life journey with greater awareness and purpose.
        </p>
        <p>The practice of Vedic Astrology involves several key components:</p>
        <div className="enlarged-text">
        <ol>
            <li><strong>The Natal Chart: </strong>Also called a birth chart or Kundali, this diagram represents the positions of planets, stars, and celestial points at the exact moment of a person's birth. It forms the foundation for all astrological analysis.</li>
            <li><strong>Planets: </strong>Vedic Astrology primarily focuses on nine celestial bodies, known as the Navagraha. These include the Sun, Moon, Mars, Mercury, Jupiter, Venus, Saturn, and the lunar nodes Rahu and Ketu. Each planet carries unique energies and significations.</li>
            <li><strong>Houses: </strong>The chart is divided into 12 houses, each representing different areas of life such as career, relationships, spirituality, and health. The interplay between planets and houses forms a key aspect of interpretation.</li>
            <li><strong>Signs: </strong>The zodiac is divided into 12 signs, each with its own characteristics and rulerships. Unlike Western astrology, Vedic Astrology places less emphasis on Sun signs and more on the Ascendant or rising sign.</li>
            <li><strong>Nakshatras: </strong>These are 27 lunar mansions or asterisms, providing additional nuance to planetary positions and their effects.</li>
        </ol>
        </div>
        <p>
        One of the distinguishing features of Vedic Astrology is its emphasis on remedial measures. Recognizing that planetary influences can be challenging, this system offers various methods to mitigate difficulties and enhance positive energies. These may include mantras, gemstones, charitable acts, or specific rituals tailored to an individual's chart.
        </p>
        <p>
        Vedic Astrology is not merely a predictive tool, but a holistic system for understanding oneself and one's place in the cosmos. It encourages self-reflection, spiritual growth, and the development of consciousness. By revealing karmic patterns, it allows individuals to work consciously with their innate tendencies and challenges, potentially transforming obstacles into opportunities for growth.
        </p>
        <p>
        For those new to Vedic Astrology, it's important to approach the subject with an open mind and a willingness to explore its depth. While the basics can be learned relatively quickly, true mastery of this ancient science requires years of study and practice. Many practitioners combine their astrological knowledge with other Vedic sciences such as Ayurveda and Yoga, creating a comprehensive approach to well-being and self-realization.
        </p>
        <p>
        As you delve into the world of Vedic Astrology, remember that it is a tool for self-discovery and empowerment, not a deterministic system. The stars may incline, but they do not compel. By understanding the cosmic influences at play in your life, you can make more informed choices and align yourself more closely with your higher purpose.
        </p>
        <p>
        Whether you're seeking insights into your career path, relationships, or spiritual journey, Vedic Astrology offers a rich tapestry of wisdom to explore. As you begin this journey, may the ancient light of the stars illuminate your path and guide you towards greater self-understanding and fulfillment.
        </p>
        <h3>The Birth Chart</h3>
        <p>
        The birth chart, or Rasi chart in Vedic astrology, is fundamental to understanding one's horoscope. To create a birth chart, you need the date, time, and place of birth. Accurate birth time is crucial, preferably verified through written records like hospital records or birth certificates. In Vedic Astrology, a very popular type of birth chart is the Diamond Chart (North Indian Style). It is a diamond-shaped chart divided into twelve houses. This chart emphasizes the houses. Each house represents different aspects of life. The chart is read counterclockwise, starting from the top center diamond, which represents the ascendant or Lagna. This chart places the ascendant at the top and is aligned vertically to reflect the human figure metaphorically. It is considered more effective for reading karmas and life areas.
        </p>
        <h3>Structure of the North Indian Chart</h3>
        <img 
          src="/north_indian_chart.png"
          alt="North Indian Vedic Chart Structure" 
          style={{ width: '700px', height: 'auto', margin: '5px 0' }}
        />
        <div className="enlarged-text">
          <ol>
            <li>
              <strong>Ascendant (Lagna):</strong> The ascendant is the first house and is located at the top center diamond of the chart. It represents the starting point of the chart and the zodiac sign that was rising on the eastern horizon at the time of birth.
            </li><br></br>
            <li>
              <strong>Houses:</strong> The chart is divided into twelve houses, each with specific significations:
              <ul>
                <li><strong>1st House (Ascendant):</strong> Self, personality, physical body.</li>
                <li><strong>2nd House:</strong> Wealth, family, speech.</li>
                <li><strong>3rd House:</strong> Siblings, courage, communication.</li>
                <li><strong>4th House:</strong> Home, mother, comforts.</li>
                <li><strong>5th House:</strong> Children, creativity, intelligence.</li>
                <li><strong>6th House:</strong> Health, enemies, debts.</li>
                <li><strong>7th House:</strong> Marriage, partnerships.</li>
                <li><strong>8th House:</strong> Longevity, mysteries, transformations.</li>
                <li><strong>9th House:</strong> Fortune, religion, higher learning.</li>
                <li><strong>10th House:</strong> Career, reputation, public life.</li>
                <li><strong>11th House:</strong> Gains, aspirations, friendships.</li>
                <li><strong>12th House:</strong> Expenditure, losses, spirituality.</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Signs:</strong> The signs are labeled as numbers in the North Indian chart. Number 1 is the first zodiac sign (Aries), number 2 represents the second sign (Taurus), and so on. They follow an anticlockwise direction.
            </li>
          </ol>
          <p>
          <strong>Important:</strong> The houses are typically not labeled in a Vedic birth chart, although it is done so in the image above. The houses are static and remain in the same place always. This is a very important distinction as beginners can easily confuse the numbers as being house numbers instead of the signs. The numbers in the birth chart signify the zodiac sign and this varies for individuals. The example chart above is that of a Virgo rising (ascendant), which is the sixth sign of the zodiac.
          </p>
        </div>
        <h3>Understanding the Signs and Planets</h3>
        <p>
          <strong>Zodiac Signs:</strong> There are twelve zodiac signs, each occupying one house in the chart. The signs (in order) are Aries, Taurus, Gemini, Cancer, Leo, Virgo, Libra, Scorpio, Sagittarius, Capricorn, Aquarius, and Pisces. Each sign has unique characteristics and influences the planets placed within them.
        </p>
        <p>
          <strong>Planets:</strong> The main planets in Vedic Astrology include the Sun, Moon, Mars, Mercury, Jupiter, Venus, Saturn, Rahu (North Node), and Ketu (South Node). Each planet has specific significations and influences the houses and signs it occupies.
        </p>
        <p>
          <strong>Sun (Surya):</strong> Represents the soul, leadership, and vitality.
        </p>
        <p>
          <strong>Moon (Chandra):</strong> Governs the mind, emotions, and maternal aspects.
        </p>
        <p>
          <strong>Mars (Mangala):</strong> Signifies energy, courage, and conflicts.
        </p>
        <p>
          <strong>Mercury (Budha):</strong> Associated with intellect, communication, and business.
        </p>
        <p>
          <strong>Jupiter (Guru):</strong> Represents wisdom, growth, and prosperity.
        </p>
        <p>
          <strong>Venus (Shukra):</strong> Governs love, beauty, and artistic talents.
        </p>
        <p>
          <strong>Saturn (Shani):</strong> Signifies discipline, challenges, and longevity.
        </p>
        <p>
          <strong>Rahu:</strong> Indicates worldly desires and materialism.
        </p>
        <p>
          <strong>Ketu:</strong> Represents spiritual pursuits and detachment.
        </p>
        <h2>Counting Positions using the North Indian Chart</h2>
        <p>
            Let us look at the example chart below. We will learn how to count how far away a planet is from a certain house or position.
        </p>
        <img 
          src="/intro_counting_example.png"
          alt="Example of Counting Positions in North Indian Chart" 
          style={{ width: '500px', height: 'auto', margin: '5px 0' }}
        />
        <div className="enlarged-text">
          <p>
            To count positions, we move counterclockwise starting from a desired location. We traverse the houses in numerical order. Notice how the arrows are oriented in the chart above. The arrows go from house to house in numerical order. This is the pattern that we move in when counting. The chart above is that of a Cancer ascendant. In this chart, Venus is in the first house. Venus is 4 positions away from its own sign of Libra, which is in the 4th house. Venus is also 11 positions away from its own sign of Taurus, which is in the 11th house. Now let us look at Saturn, which is in the 12th house. Saturn is 8 positions away from its own sign of Capricorn, which is in the 7th house. Saturn is also 9 positions away from its own sign of Aquarius, which is in the 8th house. If all of this is confusing, please take a moment to review this concept. Counting is very important when it comes to analyzing your charts and understanding the aspects of other planets.
          </p>
        </div>
        
        <div style={{ margin: '20px 0' }}>
                    {/* Intro2 */}
                    <ins className="adsbygoogle"
                        style={{ display: 'block' }}
                        data-ad-client="ca-pub-1752350757192424"
                        data-ad-slot="9283663617"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
        </div>

        <h2>Reading the North Indian Chart</h2>
        <div className="enlarged-text">
          <h3>Two main methods:</h3>
          <ol>
            <li>Signs in Houses from the Rising Sign: Understanding the impact of each sign in the houses starting from the rising sign.</li>
            <li>Lord of Each Sign/House: Analyzing where the lord of each sign or house is located in the chart to get deeper insights.</li>
          </ol>
        </div>
        <h3>Method 1: Signs in Houses from Ascendant</h3>
        <p>
          <strong>Aquarius Ascendant Example:</strong> Second House: Pisces influences money and resources. Third House: Aries impacts communication and siblings. Fourth House: Taurus relates to home and family. Each sign carries specific karmas that manifest in the houses they occupy. For example, a sign in the second house affects how one deals with resources, while a sign in the third house influences communication and relationships with siblings.
        </p>
        <h3>Key Characteristics for all Twelve Ascendant Signs</h3>
        <p>
        Please note that these characteristics are general tendencies based on the signs' placements, and that the specific placement of planets and other factors in an individual's chart will further refine and potentially alter these interpretations. Additionally, this list is not comprehensive as that would be far too time-consuming to accomplish for all the placements.
        </p>
        <div className="enlarged-text">
          <ol>
            <li>
              <strong>Aries Ascendant:</strong>
              <ul>
                <li>Cardinal signs in angular houses offering freedom but potential for impulsive decisions</li>
                <li>Cancer in 4th house: emotional attachment to home and mother</li>
                <li>Libra in 7th house: quick to fall in love, changeable relationships</li>
                <li>Capricorn in 10th house: career success comes late, requires hard work</li>
                <li>Scorpio in 8th and Aquarius in 11th: difficult karmas in investments and friendships</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Taurus Ascendant:</strong>
              <ul>
                <li>Fixed signs in angular houses providing persistence but potential for stuck situations</li>
                <li>Leo in 4th house: domineering in home life</li>
                <li>Scorpio in 7th house: challenging, transformative relationships</li>
                <li>Aquarius in 10th house: career involves serving humanity, potential for loss</li>
                <li>Competitive nature due to Venus ruling both 1st and 6th houses</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Gemini Ascendant:</strong>
              <ul>
                <li>Mutable signs in angular houses facing many choices in life</li>
                <li>Virgo in 4th house: analytical approach to home life</li>
                <li>Sagittarius in 7th house: philosophical outlook on relationships</li>
                <li>Pisces in 10th house: career may involve creativity or spirituality</li>
                <li>Leo in 3rd house: fixed karmas with younger siblings</li>
                <li>Scorpio in 6th house: transformative health issues</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Cancer Ascendant:</strong>
              <ul>
                <li>Cardinal signs in angular houses allowing for big changes</li>
                <li>Libra in 4th house: good for home improvements</li>
                <li>Capricorn in 7th house: potential for older or more experienced partners</li>
                <li>Aries in 10th house: pioneering career moves</li>
                <li>Fixed karmas in 2nd house (Leo): financial security important</li>
                <li>Karmic factors with children (Scorpio in 5th) and business (Aquarius in 8th)</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Leo Ascendant:</strong>
              <ul>
                <li>Fixed signs in angular houses very determined</li>
                <li>Scorpio in 4th house: intense, potentially traumatic home life</li>
                <li>Aquarius in 7th house: karmic debts in relationships</li>
                <li>Taurus in 10th house: potential for workplace popularity</li>
                <li>Capricorn in 6th house: need for strict health regimen</li>
                <li>Aries in 9th house: good father relationship, higher education</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Virgo Ascendant:</strong>
              <ul>
                <li>Mutable signs in angular houses adaptable but can get stuck in details</li>
                <li>Sagittarius in 4th house: philosophical approach to home life</li>
                <li>Pisces in 7th house: idealistic relationships</li>
                <li>Gemini in 10th house: versatile career</li>
                <li>Fixed karmas in 3rd (Scorpio) and 6th (Aquarius) houses: communication and health challenges</li>
                <li>Good with children and investments (Aries in 8th house)</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Libra Ascendant:</strong>
              <ul>
                <li>Cardinal signs in angular houses lots of choices and decision-making ability</li>
                <li>Capricorn in 4th house: potential for ruthless decisions in domestic life</li>
                <li>Aries in 7th house: contentious relationships need to see Mars placement</li>
                <li>Cancer in 10th house: public popularity in career</li>
                <li>Fixed karmas in 2nd (Scorpio) and 5th (Aquarius) houses: financial and children-related challenges</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Scorpio Ascendant:</strong>
              <ul>
                <li>Fixed signs in angular houses very determined and potentially stuck</li>
                <li>Aquarius in 4th house: karmic debts related to home and mother</li>
                <li>Taurus in 7th house: potential for loyal, helpful spouse</li>
                <li>Leo in 10th house: leadership in career</li>
                <li>Competitive nature due to Mars ruling both 1st and 6th houses</li>
                <li>Deep karmas in 11th house (Virgo): intense friendships, possible traumatic splits</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Sagittarius Ascendant:</strong>
              <ul>
                <li>Mutable signs in angular houses flip-flopping between situations</li>
                <li>Pisces in 4th house: emotional, potentially confusing home life</li>
                <li>Gemini in 7th house: quick attractions, multiple relationships</li>
                <li>Virgo in 10th house: detail-oriented career</li>
                <li>Aries in 5th house: good with children, active parenting</li>
                <li>Scorpio in 12th house: deep spiritual or occult interests</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Capricorn Ascendant:</strong>
              <ul>
                <li>Cardinal signs in angular houses ambitious, life-changing decisions</li>
                <li>Aries in 4th house: potential conflicts with mother or in early home life</li>
                <li>Cancer in 7th house: emotional factors affect relationships</li>
                <li>Libra in 10th house: diplomatic career, public relations</li>
                <li>Fixed karmas in 5th (Leo) and 8th (Scorpio) houses: challenges with children and joint finances</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Aquarius Ascendant:</strong>
              <ul>
                <li>Fixed signs in angular houses determined to push through</li>
                <li>Taurus in 4th house: stable but potentially stubborn home life</li>
                <li>Leo in 7th house: challenges in intimate relationships, potential for famous spouse</li>
                <li>Scorpio in 10th house: secretive or transformative career</li>
                <li>Aries in 3rd house: aggressive communication style</li>
                <li>Cancer in 6th house: nurturing approach to health and work</li>
              </ul>
            </li><br></br>
            <li>
              <strong>Pisces Ascendant:</strong>
              <ul>
                <li>Mutable signs in angular houses flip-flopping between spiritual and material focus</li>
                <li>Gemini in 4th house: changeable home life</li>
                <li>Virgo in 7th house: analytical approach to relationships</li>
                <li>Sagittarius in 10th house: philosophical or educational career</li>
                <li>Fixed karmas in 6th (Leo) and 9th (Scorpio) houses: health issues and challenges with higher education or father</li>
                <li>Cancer in 5th house: very fertile, good with children and learning</li>
              </ul>
            </li>
          </ol>
        </div>
        <h3>Method 2: Analyzing the Lord of Each Sign/House</h3>
        <p>
          The second method involves analyzing the position of the lord of each sign or house in the chart to understand the results.
          <br></br>
          <strong>Aquarius Ascendant Example:</strong> To analyze the children situation, look at the fifth house where Gemini is located and find the position of Mercury (lord of Gemini). To analyze relationships, look at the seventh house where Leo is located and find the position of the Sun (lord of Leo).
        </p>
        <h3>General Rules for Analyzing Planets Based on Distance from Own Sign</h3>
        <div className="enlarged-text">
          <ol>
            <li>Planets that are 1/5/9 positions away give good results.</li>
            <li>Planets that are 4/10 positions away also give favorable outcomes.</li>
            <li>Planets that are 2/3/11/12 positions away create challenges.</li>
            <li>Planets that are 6/8 positions away bring difficult karmas.</li>
            <li>Planets that are 7 positions away are the weakest.</li>
          </ol>
          <h3>Example Analysis using Method 2</h3>
          <p>
            Let us take a look at the following chart below which is that of an Aquarius ascendant. We will analyze it using method 2 as discussed above.
          </p>
          <img 
          src="/intro_half_example.png"
          alt="Reading the North Indian Chart" 
          style={{ width: '500px', height: 'auto', margin: '5px 0' }}
          />
          <p><strong>Sun in Aquarius (1st house):</strong> Sun rules Leo (7th house), indicating weak results for relationships as it is 7 positions away from its own sign.</p>
          <p><strong>Saturn in Taurus (4th house):</strong> Saturn rules Aquarius (1st house) and Capricorn (12th house), giving good results for personal decisions and foreign ventures as it is 4 and 5 positions away from its own signs respectively.</p>
          <p><strong>Moon in Leo (7th house):</strong> Moon rules Cancer (6th house), indicating challenges in health and workplace stress as it is 2 positions away from its own sign.</p>
        </div>
        <h3>Additional Important Concepts</h3>
        <p>
          <strong>Nakshatras:</strong> The 27 lunar mansions or Nakshatras are another critical element in Vedic Astrology. Each Nakshatra spans 13 degrees and 20 minutes and provides deeper insights into an individual's personality and destiny.
        </p>
        <p>
          <strong>Aspects:</strong> In Vedic Astrology, aspects refer to the influence that planets have on each other and on different houses. For instance, Saturn has a strong influence on the 3rd, 7th, and 10th houses from its position.
        </p>
        <p>
          <strong>Yogas:</strong> These are specific planetary combinations that create significant effects in a person's life. For example, Gajakesari Yoga, formed by the Moon and Jupiter, is considered highly auspicious.
        </p>
        <p>
          <strong>Dasha Systems:</strong> The Vimshottari Dasha system is the most widely used in Vedic Astrology. It divides a person's life into planetary periods, each governed by a different planet. Understanding your current Dasha can provide insights into the challenges and opportunities you might face.
        </p>
      </section>
    </main>
  );
}

export default Intro;
